$natural-dark: #090832;
$natural-grey: #383759;

.blankWorkspace {
  height: 74vh;

  .centered {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .paragraph-03 {
    width: 320px;
    height: 44px;
    /* Paragraph/03 */

    font-weight: 400;
    font-size: 14px;
    /* or 157% */

    /* Foundation/Neutral/Grey 01 */

    color: $natural-grey;
  }
}
