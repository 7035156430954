.general-text-styles {
  /* Body-Bold/03 */

  font-family: "DM Sans";
  font-style: normal;
  font-size: 14px;
  line-height: 18px;

  /* Foundation/Neutral/Grey 01 */

  color: #383759;
}

.heading {
  /* identical to box height, or 129% */

  letter-spacing: -0.4px;
}

.common-text-styles {
  font-weight: 400;
  letter-spacing: -0.2px;
}

.type {
  text-align: right;
  text-transform: uppercase;

  /* Foundation/Neutral/Grey 02 */
  color: #676780;
}

.child-item-icon{
  min-width: 24px !important;
  margin-right: 5px;
}
.database-icon{
  height: 20px;
  width: 20px;
}
.parent-tree-item{
  height: 40px;
}
.child-tree-item{
  padding: 2% 10% !important;
}
.sub-header{
  margin: 5px !important;
  color: #090832 !important;
}