// .ace-tm .ace_gutter {
//   background: #865454;
// }

$font-family_2: "DM Sans";

.sqlEditor {
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  min-height: 200px;
}

.sqlEditor .ace_gutter {
  background: transparent;
}

.sqlEditor .ace_keyword {
  color: #0A67F1;
}

.sqlEditor .ace_scrollbar {
  height: 100% !important;
}

.sqlEditor .ace_content {
  height: 100% !important;
}

.gridDivider {
  padding-right: 2px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-width: 0 1px 0 0;
}

.react-split__pane {
  padding-bottom: 20px;
}

.sql-editor-header {
  color: #090832;
  font-family: $font-family_2 !important;
  font-size: 14px;
  font-weight: 700 !important;
  line-height: 18px;
  letter-spacing: -0.4000000059604645px;
  text-align: left;
}

.toggle-icon {
  background-color: #090832 !important;
  color: white !important;
  height: 30px;
  width: 30px;
  margin-left: 10px;
}