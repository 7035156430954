$natural-grey: #676780;
$background-color: #f5f5f5;

.mainPage {
  height: 100vh;
  background: $background-color;
}

.MuiInputBase-root {
  border-radius: 8px !important;
  // width: 96.7%;
  button {
    background: white;
    svg {
      color: $natural-grey;
    }
  }
}
