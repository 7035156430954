$lavender-violet: #794eff;
$natural-dark: #090832;
$font-family_1: "Space Grotesk";
$font-family_2: "DM Sans";

.drawerHeader {
  justify-content: space-between !important;
  padding: 0 2% 0 10% !important;
}

.header {
  font-family: $font-family_1 !important;
  font-weight: 700 !important;
  letter-spacing: -0.02em !important;
}

.title {
  /* Header - Space Grotesk */

  font-family: "Space Grotesk" !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 22px !important;
  line-height: 28px !important;
  /* identical to box height, or 127% */

  letter-spacing: -0.8px !important;

  /* Foundation/Neutral/Dark */

  color: $natural-dark;
}

.text-common-styles {
  font-family: $font-family_2 !important;
  font-style: normal !important;
  line-height: 22px !important;
  letter-spacing: -0.2px !important;
}

.title-bold {
  width: 307px;
  // height: 22px;
  /* Title-Bold/07 */

  font-weight: 700 !important;
  font-size: 20px !important;
  /* identical to box height, or 110% */

  letter-spacing: -0.4px !important;

  /* Foundation/Neutral/Dark */

  color: $natural-dark;
}
