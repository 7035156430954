$dark-violet: #6430ff;
$violet-500: #794eff;
$grey-05: #0908320a;
$font-family_1: "DM Sans";

.form-dialog {
  background: rgba(56, 55, 89, 0.8);

  .text-common-styles {
    font-family: $font-family_1 !important;
    font-style: normal !important;
    line-height: 22px !important;
    letter-spacing: -0.2px !important;
  }

  .MuiPaper-root {
    width: 482px;
    // height: 400px;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .radio-border {
    border: 1px solid #c6c6ce;
    border-radius: 8px;
  }

  .radio-group {
    display: flex;
    justify-content: space-between;
    padding-left: 12px;
    width: 104%;
  }
}
