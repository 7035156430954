.text {
    text-align: left;
    margin: 10px !important;
}

.box-container {
    height: 90vh;
    padding: 10px;
    box-shadow: -4px 0px 0px rgba(0, 0, 0, 0.2);
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin: 30px auto;
}

.text-container {
    margin: 25px auto;
    word-wrap: break-word;
    max-width: 11rem;
}