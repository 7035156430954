.button-01 {
  /* Auto layout */

  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 10px 18px !important;
  // padding-top: 14px !important;
  gap: 10px !important;
  /* Primary */

  box-shadow: 0px 4px 28px rgba(9, 8, 50, 0.04) !important;
  border-radius: 12px !important;
  .button-text-01 {
    // margin: 0;
    /* Body-Med/03 */

    text-transform: none !important;

    font-family: "DM Sans" !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 18px !important;
    /* identical to box height, or 129% */
    letter-spacing: -0.2px !important;

    /* Inside auto layout */

    flex: none !important;
    order: 0 !important;
    flex-grow: 0 !important;

    margin-bottom: 0;
    align-items: center;
  }
}
