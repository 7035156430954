.list-item {
  margin-top: 10px !important;
  background-color: #F3E8FF;
  font-weight: 700 !important;
}

.inner-text {
  color: blue !important;
  font-weight: 700 !important;
}

.display-container {
  width: 600px;
  min-width: 300px;
  margin: 5px auto;
  padding: 20px;
  background-color: #FFFFFF;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.cloud-configure-container {
  width: 500px;
  height: 300px;
  margin: 1em auto;
  padding: 20px;
  background-color: #FFFFFF;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}