.parent-container{
 height: 100vh !important;
 background: #FBFBFF !important;   
}
.main-container{
    height: 100%;
    background: white;
    margin: 20px;
    border-radius: 20px;
}
.normal {
    font-family: 'Space Grotesk', sans-serif !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 24px;
    letter-spacing: -0.8px;
  }
  

  .bold {
    font-family: 'Space Grotesk', sans-serif !important;
    font-weight: 700 !important;
    font-size: 18px !important;
    line-height: 24px;
    letter-spacing: -0.8px;
  }