$font-family_2: "DM Sans";

.label{
    color: #383759;
    margin: 0rem 2rem !important;
    font-family: $font-family_2;
}
.profileHeader{
        color: #794EFF;
        margin: 1rem 2rem !important;     
        font-family: $font-family_2;
}
.buttonContainer{
    position: absolute;
    bottom: 10px ;
    right: -20px ;
    margin-top: 10px ;
    
}
.parentContainer{
    background-color: #0908320A;
    border-radius: 20px ;
    margin: 10px auto !important;
    padding-bottom: 20px;
   
}

.taxRegContainer{
    padding: 0 10px 0 0;
}