$lavender-violet: #794eff;
$pale-violet: #c1aeff;
$natural-grey: #676780;
$dark-blue: #090832;
$greyish-blue: #383759;
$pure-white: #ffffff;
$error-red: #c60628;
$light-grey: #9796a7;
$pale-grey: #c6c6ce;
$check-green: #00943b;
$font-family_1: "Space Grotesk";
$font-family_2: "DM Sans";

.header {
  margin: 0;
  padding-top: 3.945%;
  text-align: center;
  font-family: $font-family_1;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  color: $lavender-violet;
}
.description {
  margin: 0 !important;
  font-family: $font-family_2 !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  text-align: center !important;
  color: $natural-grey !important;
}
button[disabled] {
  background: $pale-violet;
}
.error {
  color: $error-red;
  font-family: $font-family_2;
}
.passwordError {
  color: $greyish-blue;
  font-family: $font-family_2;
}
.passwordErrorIcon {
  color: $light-grey;
}
.passwordConditionMatchIcon {
  color: $check-green;
}
